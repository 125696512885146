import * as React from 'react';

import './cookie.scss';

import cookie from './cookie.png';

const DEFAULT_CLASSNAME = 'cookie';

export const Cookie = () => {
    return (
        <div className={`${DEFAULT_CLASSNAME}_wrapper`}>
            <div className={DEFAULT_CLASSNAME}>
                <div className={`${DEFAULT_CLASSNAME}_text colored`}>{"Политика в отношении cookie-файлов"}</div>
                <div className={`${DEFAULT_CLASSNAME}_text_title`}>{"Общие положения"}</div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"Cookie-файлы (Куки) — небольшой фрагмент данных, отправленный веб-сервером и хранимый на цифровом устройстве пользователя - компьютере, ноутбуке, планшете, смартфоне и т.п. Веб-клиент (обычно веб-браузер) каждый раз при попытке открыть страницу соответствующего сайта пересылает этот фрагмент данных веб-серверу в составе HTTP-запроса. Cookie-файлы не позволяют идентифицировать личность."}</div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"Cookie-файлы используются в целях сбора статистических данных, которые позволяют определить, какие разделы сайта привлекают наибольшее внимание посетителей, а также в целях улучшения условий пользования сайтом посетителями. Пользователь может отказаться от использования Cookie-файлов, установив соответствующим образом настройки браузера согласно инструкциям, представленным ниже. Блокировка cookie-файлов может повлиять на функциональные возможности сайта."}</div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"Продолжая использовать сайт без внесения изменений в настройки браузера, пользователь предоставляет согласие ООО «Онлайн прогресс» на использование файлов cookie."}</div>
                <div className={`${DEFAULT_CLASSNAME}_text_title`}>{"Общие положения"}</div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"При наличии желания удалить cookie-файлы, сохраненные на личном устройстве, и настроить свой веб-браузер так, чтобы отказаться от cookie-файлов, пользователь может сделать это в разделе настроек своего браузера."}</div>
                <div className={`${DEFAULT_CLASSNAME}_box`}>
                    <div>
                        <div className={`${DEFAULT_CLASSNAME}_text`}>{"В зависимости от используемого пользователем веб-браузера, для отключения\n" +
                        "cookie-файлов могут быть применены различные способы. Для получения\n" +
                        "более подробной информации, необходимо перейти по следующим\n" +
                        "ссылкам соответственно:"}</div>
                        <ul className={`${DEFAULT_CLASSNAME}_text listed colored`}>
                            <li>Microsoft Internet Explorer</li>
                            <li>Google Chrome</li>
                            <li>Safari</li>
                            <li>Firefox</li>
                            <li>Opera</li>
                        </ul>
                    </div>
                    <img src={cookie} alt={'cookie'} />
                </div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"При полном отключении cookie-файлов некоторые услуги не могут быть предоставлены пользователю, доступ ко всем разделам веб-сайта в данном случае возможен лишь в режиме ограниченного пользования."}</div>
                <div className={`${DEFAULT_CLASSNAME}_text_title`}>{"Виды cookie-файлов, которые могут использоваться в ходе посещения Веб-сайта"}</div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"1. Аналитические cookie-файлы:\n" +
                "Следят за тем, как пользователи перемещаются по Веб-сайту, и тем, как переходят на данный сайт. Этот тип cookie-файлов используется, например, для того, чтобы понять, какой тип контента интересует пользователей больше всего. При желании пользователь может отказаться от них:"}</div>
                <ul className={`${DEFAULT_CLASSNAME}_text listed colored`}>
                    <li>Google Analytics</li>
                    <li>Yandex Metrika</li>
                </ul>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"2. Рекламные cookie-файлы:\n" +
                "Используются для показа пользователям сайта соответствующих рекламных объявлений."}</div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"3. Функциональные cookie-файлы:"}</div>
                <ul className={`${DEFAULT_CLASSNAME}_text listed`}>
                    <li>Позволяют сохранить настройки пользователя во время нахождения на сайте. Например, сохранение списка товаров в корзине покупок в интернет-магазине, авторизация, также эти файлы помогают пользователю не терять данные заполнения форм заявки в случае обновления страницы сайта</li>
                    <li>Для того, чтобы знать, что пользователь уже посещал сайт прежде, и сохранять пользователя авторизованным.</li>
                </ul>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"4. Cookie-файлы социальных сетей:\n" +
                "Размещение в социальных сетях видео и прочие предлагаемые на данном сайте сервисы, обслуживаются третьими лицами. Вышеуказанные третьи лица могут отправлять cookie-файлы на цифровое устройство пользователя, когда последний пользуется их услугами на данном сайте или в случае, если пользователь уже авторизован на сайте указанных третьих лиц."}</div>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"Перечень ссылок, перейдя по которым пользователь может узнать об используемых третьими лицами cookie-файлах:"}</div>
                <ul className={`${DEFAULT_CLASSNAME}_text listed colored`}>
                    <li>Facebook</li>
                    <li>Twitter</li>
                    <li>YouTube</li>
                    <li>Google+</li>
                    <li>VK.com</li>
                    <li>Instagram</li>
                </ul>
                <div className={`${DEFAULT_CLASSNAME}_text`}>{"ООО \"Онлайн проресс\" не несет ответственность за контент, способы сбора и обработки информации сторонними сайтами третьих лиц, в том числе сайтами третьих лиц, ссылки на которые могут быть опубликованы на настоящем ресурсе. Каждому пользователю необходимо ознакомиться с политикой конфиденциальности тех ресурсов, которые он посещает, прежде чем предоставлять свои данные."}</div>
            </div>
        </div>
    )
}